import React from "react";
import { FaqToggle } from "../components/FaqToggle";
import { KontaktFormAndText } from "../components/KontaktFormAndText";
import { OfficeAndMap } from "../components/OfficeAndMap";
import '../style.css';

export interface IKontaktPageProps { }

export const KontaktPage: React.FC<IKontaktPageProps> = (props) => {
  return (
    <div>
      <KontaktFormAndText
        title="Hör av er till oss"
        text="Här kan du kontakta oss om frågor gällande utveckling av er verksamhet eller om du har några andra specifika frågor. Vi hörs!"
        linkTextBtn="Skicka"
      />
      <OfficeAndMap />
    </div>
  );
}


import { PropaneSharp } from "@mui/icons-material";
import * as React from "react";

export interface ILineSeparatorProps { 
    className?: string;
}

export const LineSeparator: React.FC<ILineSeparatorProps> = (props) => {
  return (
    <div className={props.className} style={{
      height: "1px",
      width: "100%",
      backgroundColor: "#F0856A"
    }}></div>
  );
}
import React, { useEffect, useState } from "react";
import { OfficeAndMap } from "./OfficeAndMap";
import "./KontaktFormAndText.css";
import mattiasPic from "../assets/images/mattias2.png";
import { ContactFormEmailJs } from "./ContactFormEmailJs";

export interface IKontaktFormAndTextProps {
  title: string;
  text: string;
  linkTextBtn: string;
}

const contactFormPhone: React.CSSProperties = {
  color: "#000000",
  paddingTop: '20px',
  // ... any other styles you want
};

const linkColor: React.CSSProperties = {
  fontFamily: 'Inter, sans-serif',
  fontSize: 18,
  lineHeight: '28px',
  fontWeight: 400,
  color: '#000000',
  textDecoration: 'none',
};

export const KontaktFormAndText: React.FC<IKontaktFormAndTextProps> = (
  props
) => {
  //create state that check if it is mobile or not
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 769;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="kontaktFormAndTextContainer">
        <div className="kontaktFormAndText">
          <div className="kontaktFormAndTextText">
            <h1>{props.title}</h1>
            <p className="contactText">{props.text}</p>
            <div className="contactFormFullForm">
            <ContactFormEmailJs />
          </div>
            <div className="specialistDiv">
              <h4 className="titleSpecialistDiv">Prata med vår specialist</h4>
              <div className="kontaktUppgifterMedBild">
                <img src={mattiasPic} alt="picOne" />
                <div className="staffPicContactDetailsKontaktPage">
                  <h4>Mattias Ritzler</h4>
                  <a href="mailto:mattias@kellstransport.se" style={linkColor}>mattias@kellstransport.se</a>
                  <div style={contactFormPhone}>
                  <a href="tel:+46736732898" style={linkColor}>073-673 28 98</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="kontaktFormAndTextContainer">
        <div className="kontaktFormAndText">
          <div className="kontaktFormAndTextText">
            <h1>{props.title}</h1>
            <p className="contactText">{props.text}</p>
            <div className="specialistDiv">
              <h4>Prata med vår specialist</h4>
              <div className="kontaktUppgifterMedBild">
                <img src={mattiasPic} alt="picOne" />
                <div className="staffPicContactDetailsKontaktPage">
                  <h4>Mattias Ritzler</h4>
                  <a href="mailto:mattias@kellstransport.se" style={linkColor}>mattias@kellstransport.se</a>
                  <div style={contactFormPhone}>
                  <a href="tel:+46736732898" style={linkColor}>073-673 28 98</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contactFormFullForm">
            <ContactFormEmailJs />
          </div>
        </div>
      </div>
    );
  }
};

import React from "react";
import '../style.css'

export interface ICtaBtnOrangeProps {
    linkText?: string;
    onClick?: () => void;
    algin?: string;
    type?: string;
    value?:string;
    disabled?: boolean; 
}

export const CtaBtnOrange: React.FC<ICtaBtnOrangeProps> = (props) => {
    return (
        <button onClick={props.onClick} className="ctaBtnOrange" disabled = {props.disabled}>{props.linkText}</button>
    );
}
import React from 'react';
import './NavbarFooter.css';
import logo from '../assets/images/logoFull.png'
import { NavLink } from 'react-router-dom';

export interface NavbarFooterProps { }

export const NavbarFooter: React.FC<NavbarFooterProps> = () => {
    return (
        <div>
            <div className="footerContainer">
                <div className="footerLeft">
                    <NavLink to="/"><img src={logo} alt="logo" className={"footerNavbarLogo"} /></NavLink>
                </div>
                <div className='bothFootermenuLinks'>
                <div className="footerMenu1">
                    <NavLink to="/om-oss" className={"navButton"}>Om oss</NavLink>
                    <NavLink to="/vara-tjanster" className={"navButton"}>Våra tjänster</NavLink>
                    <NavLink to="/kontakt" className={"navButton"}>Kontakt</NavLink>
                    <NavLink to="/boka-demo" className={"navButton"}>Boka demo</NavLink>
                    <NavLink to="/integritetspolicy" className={"navButton"}>Integritetspolicy</NavLink>
                    <NavLink to="/FAQ" className={"navButton"}>FAQ</NavLink>
                </div>
                </div>
            </div>
            <div className='copyrightFooter'>
                Copyright © 2022 KELLS Transport AB. All rights reserved.
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import './TestimonialSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export interface ITestimonialSliderProps {
    headerTitle: string;
    testimonials: [
        { name: string, text: string, workTitle: string, img: string },
        { name: string, text: string, workTitle: string, img: string }
    ];
}

export const TestimonialSlider: React.FC<ITestimonialSliderProps> = (props) => {
    //check if mobile size
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 769;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    let slideprops = 2;
    if (width < breakpoint)  {
        slideprops = 1;
    }
    const { testimonials } = props;

    return (
        <div className='sectionTestimonial'>
            <h3 className='headerTitleTestimonial'>{props.headerTitle}</h3>
            <Swiper
                    centeredSlides={true}
                    slidesPerView={slideprops}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
            <div className='containerTestimonial'>
                    {testimonials.map((testimonial, index) => {
                        return (
                            <SwiperSlide>
                                <div key={index} className="sliderDiv">
                                    <div className="contentTestimonial">
                                        <p>{testimonial.text}</p>
                                        <div className="textAndPic">
                                            <div className='picTestimonial'>
                                                <img src={testimonial.img} alt="" />
                                            </div>
                                            <div className="nameAndTitle">
                                                <p><strong>{testimonial.name}</strong></p>
                                                <div className='titleTestimonial'>
                                                    <p>{testimonial.workTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
            </div>
            </Swiper >
        </div>
    );
}
import React from "react";
import { Grid } from "@mui/material";
import "./SammarbetspartnerSection.css";
import "../style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

export interface ISammarbetspartnerSectionProps {
  // title?: string;
  // img1?: string;
  // img2?: string;
  // img3?: string;
  // img4?: string;
  headerTitle: string;
  samarbetspartners: [
    {img: string}, 
    {img: string}, 
    {img: string}, 
    {img: string}
];
}

export const SammarbetspartnerSection: React.FC<
  ISammarbetspartnerSectionProps> = (props) => {
  const isMobile = window.innerWidth < 768;
  let slideprops = 4;
  let centeredSlides = false;
  
  if (isMobile) {
    slideprops = 2;
    centeredSlides = true;
  }
  const { samarbetspartners } = props;

  return (
    <div className="containerSectionSamarbetspartner">
      <h3 className="headerTitleSamarbetspartner">{props.headerTitle}</h3>
      <Swiper
        centeredSlides={centeredSlides}
        slidesPerView={slideprops}
        pagination={{
          clickable: true,
        }}
        className="SwiperSamarbetspartner"
      >
        <div className="containerSamarbetspartner">
            {samarbetspartners.map((samarbetspartners, index) => {
            return (
              <SwiperSlide>
                <div key={index} className="sliderDivSamarbetspartner">
                  <div className="contentSamarbetspartner">
                    <img src ={samarbetspartners.img}></img>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
};

{

    // <p>{samarbetspartners.text}</p>
    //                 <div className="textAndPic">
    //                   <div className="picTestimonial">
    //                     <img src={samarbetspartners.img} alt="" />
    //                   </div>
    //                   <div className="nameAndTitle">
    //                     <p>
    //                       <strong>{samarbetspartners.name}</strong>
    //                     </p>
    //                     <div className="titleTestimonial">
    //                       <p>{samarbetspartners.workTitle}</p>
    //                     </div>
    //                   </div>
    //                 </div>
  /* <div className="partnersImg">
                    <Grid item xs={6} sm={3}>
                        <img src={weebfleetImg} alt="img" />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <img src={BosImg} alt="img" />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <img src={ptvGroupImg} alt="img" />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <img src={budwheelsImg} alt="img" />
                    </Grid>
                </div> */
}

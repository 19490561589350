import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CtaSection } from "../components/CtaSection";
import hurfungerardetImg from "../assets/images/HurFungerarDetFullProd.png";
import bgHeroMovie from "../assets/videos/bgVideoVaraTjansterPage.mp4";
import ruttplanImg from "../assets/images/ruttplaneringSectionVaraTjansterPageProd.png"
import leveranserSectionImg from "../assets/images/leveranserSectionVaraTjansterPageProd.png";
// import verksamhetSectionImg from "../assets/images/verksamhetSection.png";
import verksamhetSectionImg from "../assets/images/verksamhetSectionVaraTjansterPageProd.png";
import { InfoSectionWithTextAndImg } from "../components/InfoSectionWithTextAndImg";
import '../style.css';
import './VaraTjansterPage.css';
import { HeaderBgVideo } from "../components/HeaderBgVideo";

export interface IVaraTjansterProps { }

let headertitle = "Vår kärnverksamhet";
let headerText = "Vi hjälper våra kunder med allt från leveranser till ruttplanering och verksamhetsutvärdering. Våra lösningar är skräddarsydda med målet att utveckla och föbättra just er verksamhet.";


export const VaraTjansterPage: React.FC<IVaraTjansterProps> = (props) => {

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 769;

  useEffect(() => {
      window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const navigate = useNavigate();
  const handleClickDemo = () => {
    navigate('/boka-demo')
  };
  const handleClick2 = () => {
    navigate('/kalkylator')
  };

  return (
    <div>
      <HeaderBgVideo
        // class="header"
        video={bgHeroMovie}
        headertitle={headertitle}
        headerText={headerText}
        btnTitle={"Boka en demo"}
        btnOnClick={handleClickDemo}

        
      />
      <div className="infoSectionsVaraTjanster">
        <InfoSectionWithTextAndImg
          title={"Ruttplanering"}
          text={"Klimatomställningen och digitaliseringen ställer höga krav på transportsektorn. Ruttplaneringen är ett verktyg som ökar medvetenheten om det dagliga transportbehovet, resursanvändningen, kostnadsbesparingar samt underlättar omställningen till mer klimatsamarta alternativ. Låt oss hjälpa er att planera och effektivera era dagliga transporter på bästa möjliga sätt."}
          img={ruttplanImg}
          bulletPoints={<ul> <li>Klimatsmart</li> <li>Kostnadsbesparing</li> <li>Bättre översikt</li> </ul>}
          align={"left"}
        />
        <InfoSectionWithTextAndImg
          title={"Leveranser"}
          text={"Ni kanske är i en expansionsfas och behöver utvidga fordonsflottan en aning? Eller behöver hjälp med enstaka leveranser? Vi kan hjälpa er att leverera allt från kylvaror till tunga gods. Vårt planeringsverktyg ser alltid till att transporten utförs klimatsmart och kostnadseffektivt. Kontakta oss så kan vi tillsammans hitta en skräddarsydd lösning för just er verksamhet."}
          img={leveranserSectionImg}
          bulletPoints={<ul> <li>Kostnadseffektivt</li> <li>Smidigt</li> <li>Vid behov</li> </ul>}
          align={"right"}
        />
        <InfoSectionWithTextAndImg
          title={"Verksamhetsutvärdering"}
          text={"Varför ändra ett vinnande koncept? Digitalisering innebär oftast en omställning vilket gör att det sällan omfamnas. Vi hjälper våra kunder att ta klivet rakt in i telematik och dataanalys för att utveckla verksamheten. Vi utför kundbesök där vi går igenom hela logistikkedjan, allt från orderhantering till leverans. Vi återkopplar med en rapport om hur ni kan förbättra just er logistikkedja"}
          img={verksamhetSectionImg}
          bulletPoints={<ul> <li>Effektivisering</li> <li>Resursanvändning</li> <li>Ständiga förbättringar</li> </ul>}
          align={"left"}
        />
      </div>
      {width > breakpoint ? 
        <div className="hurFungerarDetSection">
        <img src = {hurfungerardetImg} alt="Kells ruttplanering"></img>
      </div>: null}
      <div className="ctaSectionVaraTjansterPage">
        <CtaSection
          title={"Fokusera på er kärnverksamhet"}
          text={"Hör av er till oss så kan vi berätta lite mer om hur ni kan öka effektiviteten och få en mer strömlinjeformad logistikkedja."}
          btnTitle={"BOKA EN DEMO"}
          btnOnClick={handleClickDemo}
        // bgImage={SavingCalcImg}
        />
      </div>
    </div>

  );
};
import { TextField, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption } from './Dropdown';
import '../style.css';
import './SavingCalculator.css';
import { CtaBtnOrange } from './CtaBtnOrange';
import skapbilIcon from '../assets/images/skapbilIcon.png';
import lattLastbilIcon from '../assets/images/lattLastbilIcon.png';
import tungLastbilIcon from '../assets/images/tungLastbilIcon.png';
import { useNavigate } from 'react-router-dom';
import { LineSeparator } from './LineSeparator';


export interface ISavingCalculatorProps { }

const options: IDropdownOption[] = [{ value: "Diesel", text: "Diesel" }, { value: "Bensin", text: "Bensin" }, { value: "El", text: "El" }];

const defaultValues = {
    forbrukningSkapbil: 9,
    forbrukningLattLastbil: 14,
    forbrukningTungLastbil: 20,
    personalKostnadSkapbil: 250,
    personalKostnadLattLastbil: 250,
    personalKostnadTungLastbil: 250,
    bensin: 18,
    diesel: 20,
    el: 4,
    uppskattadBesparing5: 0.05,
    uppskattadBesparing10: 0.1,
    antalArbetsdagar: 250,
    arbetadeTimmar: 8,
    literBransleTillCo2: 2.3,
    literDieselTillCo2: 2.7,
    energiKalla: "Diesel"

};
export const SavingCalculator: React.FC<ISavingCalculatorProps> = () => {

    const [antalBilarSkapbil, setAntalBilarSkapbil] = React.useState<number | null>(0);
    const [antalBilarLattLastbil, setAntalBilarLattLastbil] = React.useState<number | null>(0);
    const [antalBilarTungLastbil, setAntalBilarTungLastbil] = React.useState<number | null>(0);
    const [korStrackaSkapbil, setKorStrackaSkapbil] = React.useState<number | null>(0);
    const [korStrackaLattLastbil, setKorStrackaLattLastbil] = React.useState<number | null>(0);
    const [korStrackaTungLastbil, setKorStrackaTungLastbil] = React.useState<number | null>(0);
    const [energiKallaSkapbil, setEnergiKallaSkapbil] = React.useState<string>(defaultValues.energiKalla);
    const [energiKallaLattLastbil, setEnergiKallaLattLastbil] = React.useState<string>(defaultValues.energiKalla);
    const [energiKallaTungLastbil, setEnergiKallaTungLastbil] = React.useState<string>(defaultValues.energiKalla);
    const [personalKostnadSkapbil, setPersonalKostnadSkapbil] = React.useState<number | null>(defaultValues.personalKostnadSkapbil);
    const [personalKostnadLattLastbil, setPersonalKostnadLattLastbil] = React.useState<number | null>(defaultValues.personalKostnadLattLastbil);
    const [personalKostnadTungSkapbil, setPersonalKostnadTungSkapbil] = React.useState<number | null>(defaultValues.personalKostnadTungLastbil);
    const [forbrukningLellerKwhSkapbil, setForbrukningLellerKwhSkapbil] = React.useState<number | null>(defaultValues.forbrukningSkapbil);
    const [forbrukningLellerKwhLattLastbil, setForbrukningLellerKwhLattLastbil] = React.useState<number | null>(defaultValues.forbrukningLattLastbil);
    const [forbrukningLellerKwhTungLastbil, setForbrukningLellerKwhTungLastbil] = React.useState<number | null>(defaultValues.forbrukningTungLastbil);
    const [diselKostnad, setDiselKostnad] = React.useState<number | null>(defaultValues.diesel);
    const [elKostnad, setElKostnad] = React.useState<number | null>(defaultValues.el);
    const [bensinKostnad, setBensinKostnad] = React.useState<number | null>(defaultValues.bensin);
    const [besparingDrivmedel, setBesparingDrivmedel] = React.useState<number | null>();
    const [besparingPersonal, setBesparingPersonal] = React.useState<number | null>();
    const [besparingCO2, setBesparingCO2] = React.useState<number | null>();
    const [besparingTotal, setBesparingTotal] = React.useState<number | null>();


    const Calculate = () => {
        // const state = { antalBilarSkapbil, antalBilarLattLastbil, antalBilarTungLastbil, korStrackaSkapbil, korStrackaLattLastbil, korStrackaTungLastbil, energiKallaSkapbil, energiKallaLattLastbil, energiKallaTungLastbil };

        const drivmedel = CalculateDrivmedel();
        const personal = CalculatePersonal();
        CalculateCO2();
        setBesparingTotal(drivmedel! + personal!);
        if (personal) {
            scrollToShowCalcSummary();
        }
    }

    const CalculateCO2 = () => {
        if (!(antalBilarSkapbil != null && korStrackaSkapbil != null && personalKostnadSkapbil)) return;
        const skapbil = energiKallaSkapbil === 'El' ? 0 : (antalBilarSkapbil * korStrackaSkapbil * (forbrukningLellerKwhSkapbil! / 100));
        const lattLastbil = energiKallaLattLastbil === 'El' ? 0 : (antalBilarLattLastbil! * korStrackaLattLastbil! * (forbrukningLellerKwhLattLastbil! / 100));
        const tungLastbil = energiKallaTungLastbil === 'El' ? 0 : (antalBilarTungLastbil! * korStrackaTungLastbil! * (forbrukningLellerKwhTungLastbil! / 100));

        let totalSkapbil = skapbil;
        if(energiKallaSkapbil === "Bensin"){
            totalSkapbil = totalSkapbil * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literBransleTillCo2 / 1000;
        }
        else if (energiKallaSkapbil === "Diesel"){
            totalSkapbil = totalSkapbil * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literDieselTillCo2 / 1000;
        }

        let totalLattLastbil = lattLastbil;
        if(energiKallaLattLastbil === "Bensin"){
            totalLattLastbil = totalLattLastbil * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literBransleTillCo2 / 1000;
        }
        else if (energiKallaLattLastbil === "Diesel"){
            totalLattLastbil = totalLattLastbil *  defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literDieselTillCo2 / 1000;
        }

        let totalTungLastbil = tungLastbil;
        if(energiKallaTungLastbil === "Bensin"){
            totalTungLastbil = totalTungLastbil * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literBransleTillCo2 / 1000;
        }
        else if (energiKallaTungLastbil === "Diesel"){
            totalTungLastbil = totalTungLastbil * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literDieselTillCo2 / 1000;
        }


        const total = totalSkapbil + totalLattLastbil + totalTungLastbil;
        // const total = ((skapbil + lattLastbil + tungLastbil) * defaultValues.antalArbetsdagar * defaultValues.uppskattadBesparing10 * defaultValues.literBransleTillCo2) / 1000;
        const totalRounded = Math.round(total * 100) / 100;
        setBesparingCO2(totalRounded);
    }

    const CalculatePersonal = () => {
        if (!(personalKostnadLattLastbil != null && personalKostnadTungSkapbil != null && personalKostnadSkapbil != null && forbrukningLellerKwhTungLastbil != null && forbrukningLellerKwhLattLastbil != null && antalBilarSkapbil != null && antalBilarLattLastbil != null && antalBilarTungLastbil != null && korStrackaSkapbil != null && korStrackaLattLastbil != null && forbrukningLellerKwhSkapbil != null && korStrackaTungLastbil != null && energiKallaSkapbil != "" && energiKallaSkapbil != null && energiKallaLattLastbil != "" && energiKallaTungLastbil != "" && elKostnad != null && bensinKostnad != null && diselKostnad != null)) return;
        const skapbil = antalBilarSkapbil * defaultValues.arbetadeTimmar * personalKostnadSkapbil
        const lattLastbil = antalBilarLattLastbil * defaultValues.arbetadeTimmar * personalKostnadLattLastbil
        const tungLastbil = antalBilarTungLastbil * defaultValues.arbetadeTimmar * personalKostnadTungSkapbil
        const total = (skapbil + lattLastbil + tungLastbil) * defaultValues.uppskattadBesparing5 * defaultValues.antalArbetsdagar;
        setBesparingPersonal(total);
        return total;

    }

    const CalculateDrivmedel = () => {
        if (!(forbrukningLellerKwhTungLastbil != null && forbrukningLellerKwhLattLastbil != null && antalBilarSkapbil != null && antalBilarLattLastbil != null && antalBilarTungLastbil != null && korStrackaSkapbil != null && korStrackaLattLastbil != null && forbrukningLellerKwhSkapbil != null && korStrackaTungLastbil != null && energiKallaSkapbil != "" && energiKallaSkapbil != null && energiKallaLattLastbil != "" && energiKallaTungLastbil != "" && elKostnad != null && bensinKostnad != null && diselKostnad != null)) return;
        const skapbil = antalBilarSkapbil * korStrackaSkapbil * (forbrukningLellerKwhSkapbil / 100) * GetDrivmedelPris(energiKallaSkapbil);
        const lattLastbil = antalBilarLattLastbil * korStrackaLattLastbil * (forbrukningLellerKwhLattLastbil / 100) * GetDrivmedelPris(energiKallaLattLastbil);
        const tungLastbild = antalBilarTungLastbil * korStrackaTungLastbil * (forbrukningLellerKwhTungLastbil / 100) * GetDrivmedelPris(energiKallaTungLastbil);
        const total = (skapbil + lattLastbil + tungLastbild) * defaultValues.uppskattadBesparing10 * defaultValues.antalArbetsdagar;
        setBesparingDrivmedel(total);
        return total;
    }

    const GetDrivmedelPris = (value: string): number => {
        if (value === "Diesel") {
            return diselKostnad!;
        }
        else if (value === "Bensin") {
            return bensinKostnad!;
        }
        else {
            return elKostnad!;
        }
    }

    const FormIsValid = () => {
        return antalBilarSkapbil != null && antalBilarLattLastbil != null && antalBilarTungLastbil != null && korStrackaSkapbil != null && korStrackaLattLastbil != null && korStrackaTungLastbil != null && energiKallaSkapbil != "" && energiKallaLattLastbil != "" && energiKallaTungLastbil != "";
    }

    const parseNumber = (value: string) => {
        const parsedValue = parseInt(value);
        return isNaN(parsedValue) ? null : parsedValue;
    }

    //hide refinedCalcs if not leftbtn is clicked
    const [showRefinedCalcs, setShowRefinedCalcs] = useState(false);
    
    const handleClickShowCalcRefined = () => {
        //scroll to top when clicked
        window.scrollTo(0, 70);
        setShowRefinedCalcs((prev) => !prev);
      };

    function scrollToShowCalcSummary(){
        const element = document.getElementsByClassName("placeholder");
        element[0].scrollIntoView({ behavior: 'smooth' });
    }

    //check if view is mobile
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 769;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    
    const navigate = useNavigate();
    const handleClickDemo = () => {
        navigate('/boka-demo')
      };

    if (width < breakpoint) {
        let skapbiltypKostnad;
        let skapbilkostnadMetod: (arg0: number | null) => void;
        let skapbilkostnadLabel;

        let lattLastbiltypKostnad;
        let lattLastbilkostnadMetod: (arg0: number | null) => void;
        let lattLastbilkostnadLabel;

        let tungLastbiltypKostnad;
        let tungLastbilkostnadMetod: (arg0: number | null) => void;
        let tungLastbilkostnadLabel;
    

        if (energiKallaSkapbil === "El") {
            skapbiltypKostnad = elKostnad;
            skapbilkostnadMetod = setElKostnad;
            skapbilkostnadLabel = "Elkostnad";
        }
        else if (energiKallaSkapbil === "Bensin") {
            skapbiltypKostnad = bensinKostnad;
            skapbilkostnadMetod = setBensinKostnad;
            skapbilkostnadLabel = "Bensinkostnad";
        }
        else {
            skapbiltypKostnad = diselKostnad;
            skapbilkostnadMetod = setDiselKostnad;
            skapbilkostnadLabel = "Dieselkostnad";
        }

        if (energiKallaLattLastbil === "El") {
            lattLastbiltypKostnad = elKostnad;
            lattLastbilkostnadMetod = setElKostnad;
            lattLastbilkostnadLabel = "Elkostnad";
        }
        else if (energiKallaLattLastbil === "Bensin") {
            lattLastbiltypKostnad = bensinKostnad;
            lattLastbilkostnadMetod = setBensinKostnad;
            lattLastbilkostnadLabel = "Bensinkostnad";
        }
        else {
            lattLastbiltypKostnad = diselKostnad;
            lattLastbilkostnadMetod = setDiselKostnad;
            lattLastbilkostnadLabel = "Dieselkostnad";
        }

        if (energiKallaTungLastbil === "El") {
            tungLastbiltypKostnad = elKostnad;
            tungLastbilkostnadMetod = setElKostnad;
            tungLastbilkostnadLabel = "Elkostnad";
        }
        else if (energiKallaTungLastbil === "Bensin") {
            tungLastbiltypKostnad = bensinKostnad;
            tungLastbilkostnadMetod = setBensinKostnad;
            tungLastbilkostnadLabel = "Bensinkostnad";
        }
        else {
            tungLastbiltypKostnad = diselKostnad;
            tungLastbilkostnadMetod = setDiselKostnad;
            tungLastbilkostnadLabel = "Dieselkostnad";
        }

        return(
            <div className='calcContainerMob'>
                <div className='fullCalculatorMob'>
                    <div className='pageTitleAndTextMob'>
                        <h1>Konstadskalkylator</h1>
                        <p>Beräkna hur mycket ni skulle kunna spara med hjälp av Kells.</p>
                    </div>
                    <div className='rowChoiseMob'>
                        <div className='numAndTitleMob'>
                        <img src={skapbilIcon} alt='truck' />
                            <h4>Skåpbil</h4>
                        </div>
                        <div className='inputFieldsCalcMob'>
                            <p>Antal bilar</p>
                            <TextField value={antalBilarSkapbil} onChange={(event) => setAntalBilarSkapbil(parseNumber(event.target.value))} type="number" />
                            <p>Daglig körsträcka (ange i km)</p>
                            <TextField value={korStrackaSkapbil} onChange={(event) => setKorStrackaSkapbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                            <p>Energikälla</p>
                            <Dropdown options={options} onChange={value => setEnergiKallaSkapbil(value)} value={energiKallaSkapbil} />
                            <div className={showRefinedCalcs ? 'showCalcRefined' : 'hideShowCalcRefined'}>
                            <p>Personalkostnad (kr/h)</p>
                            <TextField value={personalKostnadSkapbil} onChange={(event) => setPersonalKostnadSkapbil(parseNumber(event.target.value))} type="number" />
                            <p>Förbrukning (l/100km)</p>
                            <TextField value={forbrukningLellerKwhSkapbil} onChange={(event) => setForbrukningLellerKwhSkapbil(parseNumber(event.target.value))} type="number" />
                            <p>Drivmedelpriser (kr/l)</p>
                            <TextField value={skapbiltypKostnad} onChange={(event) => skapbilkostnadMetod(parseNumber(event.target.value))} label={energiKallaSkapbil} type="number" />
                            </div>
                        </div>
                    </div>
                    <div className='rowChoiseMob'>
                        <div className='numAndTitleMob'>
                            <img src ={lattLastbilIcon} alt='truck' />
                            <h4>Lätt lastbil</h4>
                        </div>
                        <div className='inputFieldsCalcMob'>
                            <p>Antal bilar</p>
                            <TextField value={antalBilarLattLastbil} onChange={(event) => setAntalBilarLattLastbil(parseNumber(event.target.value))} type="number" />
                            <p>Daglig körsträcka (ange i km)</p>
                            <TextField value={korStrackaLattLastbil} onChange={(event) => setKorStrackaLattLastbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                            <p>Energikälla</p>
                            <Dropdown options={options} onChange={value => setEnergiKallaLattLastbil(value)} value={energiKallaLattLastbil} />
                            <div className={showRefinedCalcs ? 'showCalcRefined' : 'hideShowCalcRefined'}>
                                <p>Personalkostnad (kr/h)</p>
                                <TextField value={personalKostnadLattLastbil} onChange={(event) => setPersonalKostnadLattLastbil(parseNumber(event.target.value))} type="number" />
                                <p>Förbrukning (l/100km)</p>
                                <TextField value={forbrukningLellerKwhLattLastbil} onChange={(event) => setForbrukningLellerKwhLattLastbil(parseNumber(event.target.value))} type="number" />
                                <p>Drivmedelpriser (kr/l)</p>
                                <TextField value={lattLastbiltypKostnad} onChange={(event) => lattLastbilkostnadMetod(parseNumber(event.target.value))} label={energiKallaLattLastbil} type="number" />
                            </div>
                        </div>
                    </div>
                    <div className='rowChoiseMob'>
                        <div className='numAndTitleMob'>
                            <img src = {tungLastbilIcon} alt ='tunglastbilicok'/>
                            <h4>Tung lastbil</h4>
                        </div>
                        <div className='inputFieldsCalcMob'>
                            <p>Antal bilar</p>
                            <TextField value={antalBilarTungLastbil} onChange={(event) => setAntalBilarTungLastbil(parseNumber(event.target.value))} type="number" />
                            <p>Daglig körsträcka (ange i km)</p>
                            <TextField value={korStrackaTungLastbil} onChange={(event) => setKorStrackaTungLastbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                            <p>Energikälla</p>
                            <Dropdown options={options} onChange={value => setEnergiKallaTungLastbil(value)} value={energiKallaTungLastbil} />
                            <div className={showRefinedCalcs ? 'showCalcRefined' : 'hideShowCalcRefined'}>
                            <p>Personalkostnad (kr/h)</p>
                            <TextField value={personalKostnadTungSkapbil} onChange={(event) => setPersonalKostnadTungSkapbil(parseNumber(event.target.value))} type="number" />
                            <p>Förbrukning (l/100km)</p>
                            <TextField value={forbrukningLellerKwhTungLastbil} onChange={(event) => setForbrukningLellerKwhTungLastbil(parseNumber(event.target.value))} type="number" />
                            <p>Drivmedelpriser (kr/l)</p>
                            <TextField value={tungLastbiltypKostnad} onChange={(event) => tungLastbilkostnadMetod(parseNumber(event.target.value))} label={energiKallaTungLastbil} type="number" />
                            </div>
                        </div>
                    </div>
                    <div className='buttonsMobile'>
                        <div className='calcbtnMob'>
                            <CtaBtnOrange linkText="Räkna på er årliga besparing" onClick={Calculate}/>
                        </div>
                        <div className='refinedCalcButtonplaceholderMob'>
                            <button onClick={handleClickShowCalcRefined} className = 'refinedCalcButton'>
                                {showRefinedCalcs ? 'GÖM FÖRFINAD UTRÄKNING' : 'FÖRFINA UTRÄKNINGEN'}
                            </button>
                        </div>
                    </div>
                    <div className = "placeholder">
                    <div className={besparingTotal ? 'showCalcSum' : 'hideCalcSum'}>
                        <div className='besparingTextContainerMob'>
                            <div className='besparingTextMob'>
                                <h4>Uppskattad årlig besparing/reduktion</h4>
                                <div className='besparingTextContentMob'>
                                    <div className='columnBesparingTextMob'>
                                        <p>Besparing</p>
                                        <span className='besparingTotalText'>{besparingTotal?.toLocaleString()} kr</span>
                                        <p>Drivmedel: {besparingDrivmedel?.toLocaleString()} kr</p>
                                        <p>Personal: {besparingPersonal?.toLocaleString()} kr</p>
                                    </div>
                                    <div className='columnBesparingCO2TextMob'>
                                        <p>CO2 utsläpp</p>
                                        <span className='besparingTotalCO2Text'>{besparingCO2?.toLocaleString()} ton</span>
                                    </div>
                                </div>
                            </div>
                                <div className='columnBesparingTextWithBtnMob'>
                                    <p>Kontakta oss och börja spara</p>
                                    <CtaBtnOrange linkText="Boka demo" onClick={handleClickDemo}/>
                                </div>
                        </div>
                    </div>
                    </div>   
                </div>
                <div className='disclamerTextMob'>
                    <p>* Vi har räknat på 250 arbetsdagar per år samt 8-timmars arbetspass. Vår erfarenhet har visat att det går att spara minst 10% i bränsleförbrukning och 5% på personalkostnaden vilket är också procentsatserna vi har använt i vår uträkning.  Varje liter diesel resulterar i ett CO2-utsläpp på 2,7kg och motsvarande siffra för bensin är 2,3kg. Besparingen och reduktionen kan bli mer eller mindre beroende på hur väl ni planerar idag.</p>
                </div>
            </div>

        );
    }            

    return (
        <div className='calcContainer'>
            <div>
                <div className='pageTitleAndText'>
                    <h1>Konstadskalkylator</h1>
                    <p>Beräkna hur mycket ni skulle kunna spara med hjälp av Kells.</p>
                </div>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>1</p>
                        <p>Antal bilar</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <div>
                        <img src = {skapbilIcon} alt ='skapbilicok' className='iconTrucksDesktop'/>
                        <p className='titelIcon'>Skåpbil</p>
                        <TextField value={antalBilarSkapbil} onChange={(event) => setAntalBilarSkapbil(parseNumber(event.target.value))} type="number" />
                        </div>
                        <div>
                        <img src = {lattLastbilIcon} alt ='lattlastbilicok'className='iconTrucksDesktop'/>
                        <p className='titelIcon'>Lätt lastbil</p>
                        <TextField value={antalBilarLattLastbil} onChange={(event) => setAntalBilarLattLastbil(parseNumber(event.target.value))} type="number" />
                        </div>
                        <div>
                        <img src = {tungLastbilIcon} alt ='tunglastbilicok'className='iconTrucksDesktop'/>
                        <p className='titelIcon'>Tung lastbil</p>
                        <TextField value={antalBilarTungLastbil} onChange={(event) => setAntalBilarTungLastbil(parseNumber(event.target.value))} type="number" />
                        </div>
                    </div>
                </div>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>2</p>
                        <p>Daglig körsträcka (ange i km)</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <TextField value={korStrackaSkapbil} onChange={(event) => setKorStrackaSkapbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                        <TextField value={korStrackaLattLastbil} onChange={(event) => setKorStrackaLattLastbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                        <TextField value={korStrackaTungLastbil} onChange={(event) => setKorStrackaTungLastbil(parseNumber(event.target.value))} type="number" placeholder='Ange i km' />
                    </div>
                </div>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>3</p>
                        <p>Energikälla</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <Dropdown options={options} onChange={value => setEnergiKallaSkapbil(value)} value={energiKallaSkapbil} />
                        <Dropdown options={options} onChange={value => setEnergiKallaLattLastbil(value)} value={energiKallaLattLastbil} />
                        <Dropdown options={options} onChange={value => setEnergiKallaTungLastbil(value)}  value={energiKallaTungLastbil}/>
                    </div>
                </div>
            </div>
            <div className={showRefinedCalcs ? 'showCalcRefined' : 'hideShowCalcRefined'}>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>4</p>
                        <p>Personalkostnad (kr/h)</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <TextField value={personalKostnadSkapbil} onChange={(event) => setPersonalKostnadSkapbil(parseNumber(event.target.value))} type="number" />
                        <TextField value={personalKostnadLattLastbil} onChange={(event) => setPersonalKostnadLattLastbil(parseNumber(event.target.value))} type="number" />
                        <TextField value={personalKostnadTungSkapbil} onChange={(event) => setPersonalKostnadTungSkapbil(parseNumber(event.target.value))} type="number" />
                    </div>
                </div>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>5</p>
                        <p>Förbrukning (l/100km)</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <TextField value={forbrukningLellerKwhSkapbil} onChange={(event) => setForbrukningLellerKwhSkapbil(parseNumber(event.target.value))} type="number" />
                        <TextField value={forbrukningLellerKwhLattLastbil} onChange={(event) => setForbrukningLellerKwhLattLastbil(parseNumber(event.target.value))} type="number" />
                        <TextField value={forbrukningLellerKwhTungLastbil} onChange={(event) => setForbrukningLellerKwhTungLastbil(parseNumber(event.target.value))} type="number" />
                    </div>
                </div>
                <div className='rowChoise'>
                    <div className='numAndTitle'>
                        <p className='circle'>6</p>
                        <p>Drivmedelpriser (kr/l)</p>
                    </div>
                    <div className='inputFieldsCalc'>
                        <TextField value={diselKostnad} onChange={(event) => setDiselKostnad(parseNumber(event.target.value))} label="Diesel" type="number" />
                        <TextField value={bensinKostnad} onChange={(event) => setBensinKostnad(parseNumber(event.target.value))} label="Bensin" type="number" />
                        <TextField value={elKostnad} onChange={(event) => setElKostnad(parseNumber(event.target.value))} type="number" label="El" />
                    </div>
                </div>
            </div>
            <div className='buttons'>
                    <div className='leftBtn'>
                        <button onClick={handleClickShowCalcRefined} className = 'refinedCalcButton'>
                            {showRefinedCalcs ? 'GÖM FÖRFINAD UTRÄKNING' : 'FÖRFINA UTRÄKNINGEN'}
                        </button>
                    </div>
                    <div className='rightBtn'>
                        <CtaBtnOrange linkText="Räkna på er årliga besparing" onClick={Calculate}/>
                    </div>
            </div>
            <div className="placeholder">
            <div className={besparingTotal ? 'showCalcSum': 'hideCalcSum'}>
                <div className='besparingTextContainer'>
                <h4>Uppskattad årlig besparing/reduktion</h4>
                <div className='besparingTextContent'>
                    <div className='columnBesparingText'>
                        <p>Besparing</p>
                        <span className='besparingTotalText'>{besparingTotal?.toLocaleString()} kr</span>
                        <p>Drivmedel: {besparingDrivmedel?.toLocaleString()} kr</p>
                        <p>Personal: {besparingPersonal?.toLocaleString()} kr</p>
                    </div>
                    <div className='columnBesparingText'>
                    <p>CO2 utsläpp</p>
                    <span className='besparingTotalCO2Text'>{besparingCO2?.toLocaleString()} ton</span>
                    </div>
                    <div className='columnBesparingTextWithBtn'>
                        <p>Börja spara redan idag!</p>
                    <CtaBtnOrange linkText="Kontakta oss" onClick={handleClickDemo}/>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* <LineSeparator/> */}
            <div className='disclamerText'>
                    <p>* Vi har räknat på 250 arbetsdagar per år samt 8-timmars arbetspass. Vår erfarenhet har visat att det går att spara minst 10% i bränsleförbrukning och 5% på personalkostnaden vilket är också procentsatserna vi har använt i vår uträkning.  Varje liter diesel resulterar i ett CO2-utsläpp på 2,7kg och motsvarande siffra för bensin är 2,3kg. Besparingen och reduktionen kan bli mer eller mindre beroende på hur väl ni planerar idag.</p>
                </div>
        </div>
    );
};


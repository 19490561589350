import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import { Icon } from '@mui/material'
import styles from "./ThreeIconSelection.module.css";
import '../style.css';

export interface ThreeIconSectionProps {
    titelSection?: string;
    textSection?: string;
    icon1?: string;
    icon2?: string;
    icon3?: string;
    titelIcon1?: string;
    titleIcon2?: string;
    titleIcon3?: string;
    textIcon1?: string;
    textIcon2?: string;
    textIcon3?: string;
}

export const ThreeIconSection: React.FC<ThreeIconSectionProps> = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.headerAndText}>
                <h2>{props.titelSection}</h2>
                <p>{props.textSection}</p>
            </div>
            <Grid className={styles.gridContainer} container spacing={4}>
                <Grid className={styles.gridBoxLeft} item xs={4}>
                    <img src={props.icon1} className={styles.iconImg} alt="leafIcon"/>
                    <h4>{props.titelIcon1}</h4>
                    <p className={styles.gridText}>{props.textIcon1}</p>
                </Grid>
                <Grid className={styles.gridBoxMiddle} item xs={4}>
                    <img src={props.icon2} className={styles.iconImg} alt="truckIcon"/>
                    <h4>{props.titleIcon2}</h4>
                    <p className={styles.gridText}>{props.textIcon2}</p>
                </Grid>
                <Grid className={styles.gridBoxRight} item xs={4}>
                    <img src={props.icon3} className={styles.iconImg} alt="wheelIcon"/>
                    <h4>{props.titleIcon3}</h4>
                    <p className={styles.gridText}>{props.textIcon3}</p>
                </Grid>
            </Grid>
        </div>
    )
}

import React from "react";
import { LineSeparator } from "../components/LineSeparator";
import { SavingCalculator } from "../components/SavingCalculator";
import { SavingCalculatorNew } from "../components/SavingCalculatorNew";
import '../style.css';

export interface IKalkylatorPage { }

export const KalkylatorPage: React.FC<IKalkylatorPage> = (props) => {
    return (
        <div>
        <SavingCalculator/>
        {/* <SavingCalculatorNew/> */}
        <LineSeparator />
        </div>
    );
    };
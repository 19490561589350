import React from 'react';
import { Select, MenuItem } from '@mui/material';
import '../style.css';

export interface IDropdown {
    options: IDropdownOption[];
    className?: string;
    value?: string;
    onChange: (value: string) => void;
}

export interface IDropdownOption {
    value: string;
    text: string
}

export const Dropdown: React.FC<IDropdown> = (props) => {

    return (
        <Select onChange={(event) => {props.onChange(event.target.value as string)}} value={props.value}>
            {props.options.map((option, i) => <MenuItem className={props.className} value={option.value} key={i}>{option.text}</MenuItem>)}
        </Select>
    );
}
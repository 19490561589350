import React from "react";
import { CtaBtnOrange } from "./CtaBtnOrange";
import styles from "./CtaSection.module.css";
import '../style.css';

interface CtaSectionProps {
    title?: string;
    text?: string;
    bgImage?: string;
    btn?: JSX.Element;
    btnTitle?: string;
    navigate?: string;
    btnOnClick?: () => void;
}

export const CtaSection = (props: CtaSectionProps) => {
    return (
        <div className ={styles.container} style={{backgroundImage: `url('${props.bgImage}')`}}>
            <h2 className={styles.headerTitle}>{props.title}</h2>
            <p className={styles.sectionText}>{props.text}</p>
            <CtaBtnOrange onClick={props.btnOnClick} linkText={props.btnTitle}/>
        </div>
    );
};
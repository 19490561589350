import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { Select, TextField, FormControl, MenuItem  } from '@mui/material';
import { CtaBtnOrange } from './CtaBtnOrange';
import './ContactForm.css';
import { ResultType } from '@remix-run/router/dist/utils';

export const ContactFormEmailJs = () => {
    const form = useRef<HTMLFormElement>(null);

    const [successMessage, setSuccessMessage] = useState('');

    const sendEmail = (e: any) => {
        e.preventDefault();
    
        emailjs.sendForm('service_79bf31k', 'template_od1ufx9', e.target, '464yEvosbJgrp9YR_')
          .then((result) => {
              console.log(result.text);
              setSuccessMessage('Tack för ditt meddelande! Vi återkommer så snart vi kan.');
          }, (error) => {
              console.log(error.text);
          });
        e.target.reset()
      };

  return (
      <form ref={form} onSubmit={sendEmail}>
        <div className="contactFormInput">
            <div className="inputFieldContainer">
                <p className="titelInputField">Namn</p>
                <TextField className="inputField" name="name">Namn</TextField>
            </div>
            <div className="inputFieldContainer">
                <p className="titelInputField">Telefonnummer</p>
                <TextField className="inputField" name="telefonnummer"></TextField>
            </div>
            <div className="inputFieldContainer">
                <p className="titelInputField">E-post</p>
                <TextField className="inputField" name="email"></TextField>
            </div>
            <div className="inputFieldContainer">
                <p className="titelInputField">Företag</p>
                <TextField className="inputField" name="företag"></TextField>
            </div>
            <div className="inputFieldContainer">
                <p className="titelInputField">Ärende</p>
                <div className='selectDropDownDiv'>
                    <Select id="selectDropDown" placeholder="Välj ditt ärende" name="subject">
                        <MenuItem value={"Ruttplanering"}>Ruttplanering</MenuItem>
                        <MenuItem value={"Transport"}>Transport</MenuItem>
                        <MenuItem value={"Verksamhetsutveckling"}>Verksamhetsutveckling</MenuItem>
                        <MenuItem value={"Övrigt"}>Övrigt</MenuItem>
                    </Select>
                    </div>
            </div>
            <div className="inputFieldContainer">
                <p className="titelInputField">Meddelande</p>
                <TextField className="inputFieldMessage" placeholder="Skriv till meddelande.. " name="message"></TextField>
            </div>
            <div>
                <CtaBtnOrange type="submit" value="Skicka" linkText='Skicka'/>
            </div>
            {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
      </form>
);
};
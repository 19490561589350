import { Grid as div } from "@mui/material";
import React, { useEffect, useState } from "react";
import { JsxAttribute, JsxElement } from "typescript";
import styles from "./InfoSectionWithTextAndImg.module.css";
import '../style.css';
import { style } from "@mui/system";

export interface InfoSectionWithTextAndImgProps {

    title?: string;
    smallTitle?: string;
    text?: string;
    img?: string;
    align?: string;
    bulletPoints?: JSX.Element;
}  


export const InfoSectionWithTextAndImg: React.FC<InfoSectionWithTextAndImgProps> = (props) => {

    const [width, setWidth] = useState(window.innerWidth);
    const breakpointMobile = 769;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    //check if user is using mobile or desktop
    //if mobile change order of elements
    if (width < breakpointMobile) {
        return (
            <div className={styles.containerInfoSection}>
                    <h5>{props.smallTitle}</h5>
                    <h3 className={styles.bigTitle}>{props.title}</h3>
                    <p className={styles.textSection}>{props.text}</p>
                    <p>{props.bulletPoints}</p>
                    <img className={styles.imgInfoSection} src={props.img} alt="img"/>
            </div>
        )
    }
    if( props.align === "right"){
        return (
            <div className={styles.containerInfoSection}>
                <div className={styles.textLeft}>
                    <h5>{props.smallTitle}</h5>
                    <h3 className={styles.bigTitle}>{props.title}</h3>
                    <p className={styles.textSection}>{props.text}</p>
                    <p>{props.bulletPoints}</p>
                </div>
                <div className={styles.imgRight}>
                    <img src={props.img} alt="img"/>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className={styles.containerInfoSection}>
                <div className={styles.imgLeft}>
                    <img src={props.img} alt="img"/>
                </div>
                <div className={styles.textRight}>
                    <h5>{props.smallTitle}</h5>
                    <h3 className={styles.bigTitle}>{props.title}</h3>
                    <p className={styles.textSection}>{props.text}</p>
                    <p>{props.bulletPoints}</p>
                </div>
            </div>
        )
    }
}
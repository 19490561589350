
import { link } from "fs";
import { stringify } from "querystring";
import React from "react";
import { FaqToggle } from "../components/FaqToggle";
import { KontaktFormAndText } from "../components/KontaktFormAndText";
import { LineSeparator } from "../components/LineSeparator";
import '../style.css';

export interface IBokaDemoProps { 
}

export const BokaDemoPage: React.FC<IBokaDemoProps> = (props) => {
 let firstLink = <a href="https://www.myptv.com/en/logistics-software/ptv-route-optimiser?_ga=2.108516484.781822836.1664991622-1361470749.1664212103" target="_blank" rel="noreferrer">här.</a>
 let secondLink = <a href="https://www.webfleet.com/en_gb/webfleet/ " target="_blank" rel="noreferrer">här.</a>
 let thirdLink = <a href="/kalkylator" target="_blank" rel="noreferrer">här.</a>
  return (
    <div>
      <KontaktFormAndText
        title="Boka en demo"
        text="Vänligen fyll i er information i kontaktformuläret så kommer en av våra trevliga medarbetare att gå igenom allt ni behöver för att växa med oss. "
        linkTextBtn="Boka en Demo"
      />
      <FaqToggle
        title="Vanliga frågor och svar"
        toggleQuestions={
          //
          [
            {
              question: "Vilket ruttplaneringsvertyg använder ni?",
              //create a link in the answer
              answer: <div>Vi använder PTV Route Optimiser vilket är det ledande vertyget inom ruttplanering Läs mer {firstLink} </div>
            },
            {      
              question: "Vilka GPS-enheter använder ni?",
              answer:<div>Vi använder oss av enheter från Webfleet. Alla detaljer kring leveranserna skickas i korrekt ordning till respektive fordon. Läs mer om Webfleets produktportfölj {secondLink}</div>
            },
            {
              question: "Vad kostar ruttplanering per månad?",
              answer: <div>Kostnaden för tjänsten beror/baseras på storleken av er fordonsflotta samt omfattning av stöd som ni är i behov av. Kontakta oss så återkommer vid med en offert baserat på era behov.</div>
            },
            {
              question: "Hur stora besparingar kan vi göra?",
              answer: <div>Använd vår räknesnurra för att se hur mycket just ni kan göra i årliga besparing samt hur mycket ni kan sänka era CO2-utsläpp med. Testa {thirdLink}</div>
            },
            {
              question: "Hur stor är er fordonsflotta?",
              answer: "Vår fordonsflotta består idag av 10 fordon. Allt från tunga lastbilar till mindre skåpbilar samt en eldriven skåbil. "
            },
            {
              question: "Har ni kylbilar?",
              answer: "Ja, vi har kylbilar med bakgavellyft. "
            },
            {
              question: "Hur lång tid tar det att komma igång?",
              answer: "Vi ser till att minimera startsträckan med ett grundligt förarbete. Oftast tar omställningen 1-2 veckor. "
            },
            {
              question: "Är det någon bindningstid?",
              answer: "Nej, vi är övertygade om att våra tjänster ständigt förbättrar er verksamhet. Vår filosoi är att våra tjänster ska sägas upp om det inte ger något mervärde för er verksamhet."
            },
            {
              question: "Går det att hyra in personal via er?",
              answer: "Ja, kontakta oss med så återkommer med ett prisförslag."
            },
          ]
        } />
        <LineSeparator className="separatorOmOss"/>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.css";
import { NavLink } from 'react-router-dom';
import '../style.css';
import logo from '../assets/images/KellsLogo.png'
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const Navbar: React.FC<{}> = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
    }, []);

    //make a hamburger menu for mobile
    //close the menu when you click on a link

    const [open, setOpen] = React.useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 769;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    const handleClick = () => {
        setOpen((prev) => !prev);
      };
    
      const handleClickAway = () => {
        setOpen(false);
      };

      //close the menu when you click on a link
        const closeMenu = () => {
            setOpen(false);
        }


    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.container}>
            {width < breakpoint ? (
                <div className={styles.navmenuMobile}>
                    <NavLink to="/"><img src={logo} alt="logo" className={styles.logoContainer} /></NavLink>
                <div onClick={handleClick}>
                    <div className={styles.hamburger}>
                    <div className={open ? styles.line1 : styles.line1}></div>
                    <div className={open ? styles.line2 : styles.line2}></div>
                    <div className={open ? styles.line3 : styles.line3}></div>
                    </div>
                </div>
                </div>
            ) : (
                <div className={navbar ? styles.navContainerActive : styles.navContainer}>
                <nav className={styles.navbar}>
                    <NavLink to="/"><img src={logo} alt="logo" className={styles.logoContainer} /></NavLink>
                    <div className={styles.linkContainer}>
                        <NavLink to="/om-oss" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Om oss</NavLink>
                        <NavLink to="/vara-tjanster" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Våra tjänster</NavLink>
                        <NavLink to="/kontakt" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Kontakt</NavLink>
                        <NavLink to="/boka-demo" className={styles.navCtaButton}>BOKA DEMO</NavLink>
                    </div>
                </nav>
                </div>
            )}
            {open && (
                <div className={styles.mobileMenu} onClick={closeMenu}>
                     <NavLink to="/om-oss" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Om oss</NavLink>
                        <NavLink to="/vara-tjanster" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Våra tjänster</NavLink>
                        <NavLink to="/kontakt" className={({ isActive }) => (isActive ? styles.navButtonActive : styles.navButton)}>Kontakt</NavLink>
                        <NavLink to="/boka-demo" className={styles.navCtaButton}>BOKA DEMO</NavLink>
                </div>
            )}
        </div>
        </ClickAwayListener>
    )
}
import React from "react";
import '../style.css';


export interface IIntegritetspolicyPageProps { }

export const IntegritetspolicyPage: React.FC<IIntegritetspolicyPageProps> = (props) => {
    return (
        <div className="integritetspolicyContainer">
            <h1>Integritetspolicy</h1>
            <p>KELLS Transport värnar om din integritet och vi vill att du ska känna dig trygg när du använder vår webbplats. Därför har vi skapat denna integritetspolicy för att informera dig om hur vi samlar in, använder och skyddar dina personuppgifter. Vi följer alltid gällande dataskyddslagstiftning, inklusive EU:s dataskyddsförordning (GDPR).</p>

            <h3>Insamling av personuppgifter</h3>
            <p>Vi samlar in personuppgifter från dig när du lämnar dina kontaktuppgifter på vår webbplats, till exempel när du prenumererar på vårt nyhetsbrev eller fyller i ett kontaktformulär. Dessa uppgifter kan innefatta ditt namn, e-postadress och telefonnummer.</p>

            <h3>Användning av personuppgifter</h3>
            <p>Vi använder dina personuppgifter för att svara på dina förfrågningar och skicka dig information som du har begärt. Vi kan också använda dina personuppgifter för att skicka dig marknadsföring och annan information om våra tjänster och produkter.</p>

            <h3>Delning av personuppgifter</h3>
            <p>Vi kommer inte att sälja eller på annat sätt dela dina personuppgifter med tredje part utan ditt samtycke, förutom i de fall det krävs enligt lag eller för att skydda våra rättigheter.</p>

            <h3>Cookies</h3>
            <p>Vi använder cookies för att förbättra din upplevelse av vår webbplats och för att samla in statistik om webbplatsens användning. Cookies är små textfiler som lagras på din dator av din webbläsare. Du kan välja att inte acceptera cookies genom att ändra inställningarna i din webbläsare, men det kan påverka hur vår webbplats fungerar.</p>

            <h3>Google Analytics</h3>
            <p>Vi använder Google Analytics för att samla in statistik om hur vår webbplats används. Google Analytics använder cookies för att samla in information om hur användare använder webbplatsen, inklusive IP-adresser. Informationen som samlas in av Google Analytics kan delas med redje part av Google i enlighet med deras integritetspolicy. Du kan välja att inte delta i Google Analytics genom att installera en tillägg till din webbläsare.</p>
            <h3>Dina rättigheter</h3>
        <p>Enligt GDPR har du rätt att begära tillgång till dina personuppgifter som vi har samlat in, rätt att begära rättelse av felaktiga uppgifter, rätt att begära radering av dina personuppgifter och rätt att begränsa behandlingen av dina personuppgifter. Du har också rätt att invända mot behandling av dina personuppgifter och rätt att överföra dina personuppgifter till en annan personuppgiftsansvarig.</p>

        <p>Om du vill utöva någon av dina rättigheter, vänligen kontakta oss på info@kellstransport.se. Vi kan kräva att du identifierar dig för att säkerställa att vi inte delar dina personuppgifter med någon annan än dig.</p>

        <h3>Ändringar av integritetspolicyn</h3>
        <p>Vi förbehåller oss rätten att göra ändringar i denna integritetspolicy när som helst. Vi kommer att publicera eventuella ändringar på vår webbplats och rekommenderar att du regelbundet granskar denna policy för att vara informerad om hur vi samlar in och behandlar dina personuppgifter.</p>

        <h3>Kontakta oss</h3>
        <p>Om du har några frågor eller kommentarer om vår integritetspolicy, vänligen kontakta oss på info@kellstransport.se.</p>
    </div>
    )};
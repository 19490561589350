import { style } from "@mui/system";
import React from "react";
import { CtaBtnOrange } from "./CtaBtnOrange";
import styles from "./HeaderBgVideo.module.css";



export interface IHeaderBgVideoProps {
    video: string;
    headertitle: string;
    headerText: string;
    btnTitle: string;
    btnOnClick: () => void;
}

export const HeaderBgVideo: React.FC<IHeaderBgVideoProps> = (props) => {
    return (
        <header className={styles.containerHeader}>
            <div className={styles.overlay}>
            <video autoPlay muted loop controls={false} playsInline className={styles.video}>
                <source src={props.video} type="video/mp4" />
            </video>
            </div>
            <div className={styles.headerContent}>
                <div className={styles.headerText}>
                <h1>{props.headertitle}</h1>
                <p>{props.headerText}</p>
                <CtaBtnOrange onClick={props.btnOnClick} linkText={props.btnTitle} />
                </div>
            </div>
        </header>
    );
}
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FaqToggle.css';
import { JsxElement } from 'typescript';

export interface FaqToggleProps {
    toggleQuestions: [
        { question: string, answer: JSX.Element},
        { question: string, answer: JSX.Element },
        { question: string, answer: JSX.Element },
        { question: string, answer: JSX.Element },
        { question: string, answer: string },
        { question: string, answer: string },
        { question: string, answer: string },
        { question: string, answer: string },
        { question: string, answer: string },
    ];
    title?: string;
}

export const FaqToggle: React.FC<FaqToggleProps> = (props) => {
    const { toggleQuestions } = props;
    return (
        <div className='faqSection'>
            <div className='faqTitleColumn'>
                <h2>{props.title}</h2>
            </div>
            <div className='faqQuestionsColumn'>
                {toggleQuestions.map((questionAndAnswer, index) => {
                    return (
                        <Accordion className='accordion'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h4>{questionAndAnswer.question}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className='faqAnswer'>
                                    {questionAndAnswer.answer}
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div>
    );
}